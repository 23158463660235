<template>
    <div class="RoleCreate">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>

        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="exchangeNote" label-width="80px" size="small">
                <el-form-item label="分销员">
                    <el-table
                        id="printMe"
                        border
                        stripe
                        style="width: 800px"
                        :data="tableData"
                        size="mini"
                        :highlight-current-row="true"
                        max-height="500"
                    >
                        <el-table-column prop="distributionNickName" label="姓名" />
                        <el-table-column prop="distributionMobile" label="手机号" />
                        <el-table-column prop="deptName" label="所属机构" />
                    </el-table>
                </el-form-item>
                <el-form-item label="兑换类型">
                    <el-radio-group :disabled="true" v-model.number="tableData[0].exchangeType">
                        <el-radio :label="1">商品</el-radio>
                        <el-radio :label="2">现金</el-radio>
                    </el-radio-group>
                    <div class="limitTit" v-if="tableData[0].exchangeType == 1">
                        <span>本操作不扣除门店商品库存，兑换商品后请以其他方式出库，以保证库存准确性。</span>
                    </div>
                    <div v-if="tableData[0].exchangeType === 1">
                        <el-table
                            id="printMe"
                            border
                            stripe
                            style="width: 800px"
                            :data="tableData"
                            size="mini"
                            :highlight-current-row="true"
                            max-height="500"
                        >
                            <el-table-column prop="goodsCode" label="商品编码" />
                            <el-table-column prop="goodsName" label="商品名称" />
                            <el-table-column prop="bars" label="条码" />
                            <el-table-column prop="specs" label="规格" />
                            <el-table-column prop="unit" label="单位" />
                            <el-table-column prop="goodsPrice" label="销售价" />
                            <el-table-column prop="goodsCount" label="数量" />
                        </el-table>
                    </div>
                    <div v-if="tableData[0].exchangeType === 2">
                        <el-form-item label="兑换金额:" style="margin-bottom: 20px">
                            <el-input
                                :disabled="true"
                                style="width: 100px"
                                v-model="tableData[0].exchangeMoney"
                                placeholder=""
                            />元
                        </el-form-item>
                    </div>
                </el-form-item>
                <ef-remark :disabled="true" v-model="tableData[0].remake" />
            </el-form>
        </el-card>
    </div>
</template>
<script>
import EfRemark from 'components/EfRemark';

export default {
    name: 'RoleCreate',
    components: { EfRemark },
    props: ['form'],
    data() {
        return {
            exchangeNote: {
                code: '',
            },
            tableData: [],
            url: '/distribution/exchange/findByDistributionExchangeDetails/',
        };
    },
    mounted() {
        this.exchangeNote = {
            code: this.form.code,
        };
        this.handleQuery();
    },
    methods: {
        handleQuery() {
            this.$http.get(this.url + this.exchangeNote.code).then((res) => {
                this.tableData = [res.data.data];
            });
        },
    },
};
</script>
<style scoped>
.el-input {
    width: auto;
}

.el-textarea {
    width: auto;
}

.limitTit {
    color: red;
    font-size: 12px;
    line-height: 1.5;
}
</style>
